<script>
export default {
	lang: 'shop',
	props: {
		stock: Object,
		metric: String,
		metricFactor: Number,
		packMetric: String,
	},
	computed: {
		availability() {
			return this.stock.availability
		},
		qty() {
			return this.stock.qty
		},
		metricQty() {
			if (!this.metricFactor) return
			let div = Number(this.qty / this.metricFactor).toFixed(2)
			return Math.floor(div)
		},
		infiniteQty() {
			return this.stock.infiniteQty
		},
		metricWord() {
			let spl = this.metric.split('/')
			return this.qty == 1 ? spl[0] : spl.join('')
		},
	},
}
</script>

<template>
	<div v-if="availability != 'OutOfStock' && !infiniteQty" class="stock-message">
		<span v-if="qty == 1">{{ 'Última disponible!' | lang }}</span>
		<div v-else>
			<div>{{ '{qty} {metricWord} disponibles' | lang({ qty, metricWord }) }}</div>
			<div class="font-0" v-if="packMetric && metricQty">
				{{ '{metricQty} {packMetric} disponibles' | lang({ metricQty, packMetric }) }}
			</div>
		</div>
	</div>
</template>

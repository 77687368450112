<template>
	<v-menu offset-y min-width="150px" open-on-hover transition="slide-y-transition">
		<template v-slot:activator="{ on, attrs }">
			<Button class="share-button" small fab v-bind="attrs" v-on="on" elevation="0">
				<v-icon color="grey">mdi-share-variant</v-icon>
			</Button>
		</template>
		<v-list>
			<div class="list-item" v-for="{ color, social, icon, url } in socials" :key="social">
				<SafeLink :to="url">
					<Button text small block class="d-flex justify-start text-uppercase">
						<div
							class="svg-container d-flex justify-xl-center align-center"
							:style="`background-color:  ${color}`"
						>
							<v-icon color="white"> {{ icon }} </v-icon>
						</div>
						{{ social }}
					</Button>
				</SafeLink>
			</div>
		</v-list>
	</v-menu>
</template>

<script>
export default {
	props: {
		productName: String,
	},
	computed: {
		socials() {
			const share = encodeURIComponent(`Mira que buen producto! ${this.productName}`)
			const location = this.$ssr ? '#' : encodeURIComponent(window.location.href)

			return [
				{
					social: 'whatsapp',
					color: '#25d366',
					icon: 'mdi-whatsapp',
					url: `https://api.whatsapp.com/send?text=${share} ${location}`,
				},
				{
					social: 'email',
					color: '#777',
					icon: 'mdi-email',
					url: `mailto:?subject=Mira este producto&body=${share} ${location}`,
				},
				{
					social: 'facebook',
					color: '#3b5998',
					icon: 'mdi-facebook',
					url: `https://www.facebook.com/sharer/sharer.php?u=${location}`,
				},
				{
					social: 'twitter',
					color: '#55acee',
					icon: 'mdi-twitter',
					url: `http://twitter.com/share?text=${share}&url=${location}`,
				},
				{
					social: 'linkedin',
					color: '#0077b5',
					icon: 'mdi-linkedin',
					url: `https://www.linkedin.com/shareArticle?url=${location}`,
				},
			]
		},
	},
}
</script>

<style lang="scss" scoped>
.list-item {
	min-height: 30px;
	margin-bottom: 0.3rem;
	.svg-container {
		padding: 0.3rem;
		margin-right: 4px;
		border-radius: 4px;
	}
}
</style>

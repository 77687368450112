<script>
import productSheetDesignMixin from '@/v-shop/mixins/productSheetDesignMixin'

export default {
	mixins: [productSheetDesignMixin],
	props: {
		templateData: Object,
		product: Object,
	},
	data() {
		return {
			open: false,
			maxHeight: '12em',
		}
	},
	mounted() {
		if (this.showReadMoreButton && this.isContentOverflowing) this.maxHeight = '12em'
		else this.maxHeight = 'unset'
	},
	computed: {
		showReadMoreButton() {
			return this.productSheetDesignConfig?.showReadMoreButton
		},
		hasDescription() {
			return !!this.textDescription || !!this.templateData.richDescription
		},
		textDescription() {
			return this.templateData.description || this.templateData.content
		},
		descriptionHasHtml() {
			const htmlRegex = /<("[^"]*"|'[^']*'|[^'">])*>/
			return htmlRegex.test(this.textDescription)
		},
		hasHtml() {
			return this.descriptionHasHtml || this.templateData.useRichText
		},
		htmlDescription() {
			return this.templateData.useRichText ? this.templateData.richDescription : this.textDescription
		},
		cDescStyle() {
			return {
				'max-height': this.open ? 'unset' : this.maxHeight,
				overflow: this.open ? 'auto' : 'hidden',
			}
		},
		btnText() {
			return this.open ? 'MENOS' : 'MÁS'
		},
		isContentOverflowing() {
			if (!this.$refs.descriptionContent) return
			return this.$refs.descriptionContent.scrollHeight > this.$refs.descriptionContent.clientHeight
		},
	},
}
</script>

<template>
	<div class="product-page__description" v-if="hasDescription">
		<h2 class="header font-3 font-weight-bold pb-4 product-page__description-title">Descripción</h2>
		<div :style="cDescStyle" ref="descriptionContent">
			<div v-html="htmlDescription" v-if="hasHtml"></div>
			<div class="product-page__description-content" v-else>
				{{ textDescription }}
			</div>
		</div>
		<div
			class="product-page__description-read-more-btn link--text pa-3 text-center"
			@click="open = !open"
			v-if="maxHeight != 'unset'"
		>
			<span class="pointer font-0">LEER {{ btnText }}</span>
		</div>
	</div>
</template>

<style scoped lang="scss">
::v-deep .product-page__description-content {
	white-space: pre-line;
}
::v-deep img {
	width: 100%;
	height: auto;
}
</style>


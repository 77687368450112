import moment from 'moment'

export function generateProductSchema(router, product, variant, initialReviews) {
	let productUrlPath = router.resolve({
		name: 'product',
		params: { id: product.id, urlName: product.urlName },
	}).href

	let name = [product.name]
	if (variant.attrs?.length) {
		let variantName = variant.attrs.map((attr) => attr.v).join(' - ')
		name.push(`(${variantName})`)
	}
	name = name.join(' ')

	let data = {
		'@context': 'http://schema.org',
		'@type': 'Product',
		name,
		category: product.categories.map((c) => c.name).join(' > '),
		sku: variant.sku,
		url: `${process.env.VUE_APP_BASE_URL}${productUrlPath}`,
		offers: {
			'@type': 'Offer',
			url: `${process.env.VUE_APP_BASE_URL}${productUrlPath}`,
			priceCurrency: variant.pvPrice.priceConfig.currency.iso,
			priceValidUntil: moment().add(10, 'days').format('YYYY-MM-DD'),
			price: variant.pvPrice.price.toFixed(2),
			availability: `https://schema.org/${variant.stock.availability}`,
			seller: {
				'@type': 'Organization',
				name: process.env.VUE_APP_PROJECT_NAME,
			},
		},
	}

	let review = initialReviews?.map((r) => {
		return {
			'@type': 'Review',
			reviewRating: {
				'@type': 'Rating',
				ratingValue: r.rating,
			},
			author: {
				'@type': 'Person',
				name: r.user.firstname,
			},
		}
	})

	if (review) {
		data.review = review
		data.aggregateRating = {
			'@type': 'AggregateRating',
			ratingValue: product.ratingAvg,
			reviewCount: product.ratingCount,
		}
	}

	let imageUrl = product.images?.[0].medium.fullUrl
	if (imageUrl) data.image = imageUrl

	if (product.brand) {
		data.brand = {
			'@type': 'Brand',
			name: product.brand.name,
		}
	}

	if (variant.ean) {
		data.gtin = variant.ean
	}

	data.description = product.info?.description || product.name

	return data
}

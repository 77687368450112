const countdownService = {}

countdownService.intervalId = null

countdownService.startCountdown = (
	limitedTimeOfferCountdown,
	limitedTimeOfferCountdownState,
	seconds,
	callback
) => {
	if (!limitedTimeOfferCountdown) {
		countdownService.intervalId = setInterval(() => {
			seconds--
			callback(seconds)
			limitedTimeOfferCountdownState = true
		}, 1000)
	}
}

countdownService.stopCountdown = (limitedTimeOfferCountdown, limitedTimeOfferCountdownState) => {
	if (limitedTimeOfferCountdown) {
		clearInterval(countdownService.intervalId)
		limitedTimeOfferCountdownState = false
	}
}

module.exports = countdownService

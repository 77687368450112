<script>
export default {
	props: {
		product: Object,
		selectedVariantId: String,
		keyClass: String,
		valuesContainerClass: String,
		showSelectInput: {
			type: [Number, String, Boolean],
			default: 5,
		},
	},
	model: {
		prop: 'selectedVariantId',
		event: 'updateModel',
	},
	data() {
		return {
			groups: [],
		}
	},
	watch: {
		selectedVariantId() {
			this.onSelectedVariantIdChanged()
		},
	},
	computed: {
		availableItems() {
			const selected = this.groups[0].selected
			return this.geAvailableItems(selected).sort((a, b) => (a.v > b.v ? 1 : -1))
		},
		variants() {
			return [...this.product.variants].sort((a, b) => a.position - b.position)
		},
		allKeys() {
			return this.product.variants[0].attrs.map((attr) => ({
				k: attr.attrKey.k,
				displayAs: attr.attrKey.displayAs,
			}))
		},
		mainVariantId() {
			return this.variants.find(({ main }) => main).id
		},
	},
	methods: {
		getAttrData(attr) {
			if (!attr) return
			const { displayValue, displayMedia, v } = attr
			return { displayValue, displayMedia, v }
		},
		getValues(n, group) {
			return n === 0 ? group.values : this.availableItems
		},
		onInput(group, event) {
			group?.select(event)
			const available = this.geAvailableItems(this.groups[0].selected)
			const selected = this.groups[1]?.selected
			if (!selected) return
			if (!available.find((val) => val.v === selected.v)) {
				this.groups[1]?.select(available[0])
			}
		},
		geAvailableItems(selected) {
			return this.product.variants
				.filter((v) => v.attrs[0].v === selected.v)
				.map((v) => this.getAttrData(v.attrs[1]))
		},
		createGroups() {
			this.groups = []
			for (let key of this.allKeys) {
				let group = {}
				this.$assign(group, {
					key: key.k,
					values: [],
					displayAs: key.displayAs,
					selected: null,
					select: (value) => {
						if (group.selected.v == value.v) return
						group.selected = value
						this.onValueSelected()
					},
				})
				this.groups.push(group)
			}

			this.variants.forEach((variant) => {
				variant.attrs.forEach((attr) => {
					let group = this.groups.find((group) => group.key == attr.attrKey.k)
					if (!group.values.find((value) => value.v === attr.v))
						group.values.push(this.getAttrData(attr))
				})
			})
		},
		onValueSelected() {
			let selectedValues = {}
			for (let group of this.groups) {
				if (!group.selected) return
				selectedValues[group.key] = group.selected.v
			}
			for (let variant of this.variants) {
				let found = true
				for (let attr of variant.attrs) {
					if (selectedValues[attr.attrKey.k] != attr.v) {
						found = false
						break
					}
				}
				if (found) return this.$emit('updateModel', variant.id)
			}
		},
		onSelectedVariantIdChanged() {
			if (!this.selectedVariantId) return this.$emit('updateModel', this.mainVariantId)
			let variant = this.variants.find(({ id }) => id == this.selectedVariantId)
			if (!variant) return this.$emit('updateModel', this.mainVariantId)
			for (let attr of variant.attrs) {
				let group = this.groups.find((group) => group.key == attr.attrKey.k)
				group.selected = this.getAttrData(attr)
			}
		},
		shouldShowSelectInput(valuesLen) {
			if (typeof this.showSelectInput == 'boolean') return this.showSelectInput
			return valuesLen >= Number(this.showSelectInput)
		},
	},
	created() {
		//this.processVariants()
		if (this.product.hasUniqueVariant) return
		this.createGroups()
		this.onSelectedVariantIdChanged()
	},

	cssVars: {
		selector: '.product-page__variants-selector ',
	},
}
</script>

<template>
	<div class="product-page__variants-selector base" v-if="!product.hasUniqueVariant">
		<slot v-bind="{ groups }">
			<div v-for="(group, i) of groups" :key="group.key" class="pb-4">
				<slot name="key" v-bind="{ key: group.key, group, groups }">
					<div :class="keyClass || 'pb-1 font-weight-bold text-uppercase'">
						{{ group.key }}
					</div>
				</slot>
				<div :class="valuesContainerClass || 'd-flex align-center flex-wrap'">
					<slot name="values" v-bind="{ group, groups }">
						<template v-if="shouldShowSelectInput(group.values.length)">
							<Select
								item-value="v"
								return-object
								:value="group.selected"
								:items="getValues(i, group)"
								@input="onInput(group, $event)"
								class="pt-0"
							>
								<template v-slot:selection="{ item }">
									<VariantSelector-SelectItem :group="group" :item="item" />
								</template>
								<template v-slot:item="{ item }">
									<VariantSelector-SelectItem :group="group" :item="item" />
								</template>
							</Select>
						</template>
					</slot>
				</div>
			</div>
		</slot>
	</div>
</template>

<style scoped></style>

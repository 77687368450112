<script>
import Base from '#/v-shop-base-layout/vue-src/v-shop/product/comp_ProductSheetDefault.vue'

export default {
	extends: Base,
	lang: 'shop',
	data() {
		return {
			rightColInView: true,
		}
	},
	mounted() {
		if (this.$ssr || this.$b.m) return

		// check if right column is fully visible
		const rect = this.$refs.rightColContent?.getBoundingClientRect()
		const windowHeight = window.innerHeight || document.documentElement.clientHeight
		this.rightColInView = rect.bottom < windowHeight
	},
}
</script>

<template>
	<div class="pb-3">
		<v-row class="no-mobile-px" :class="$b({ d: 'grey-border-right' })">
			<v-col cols="12" :md="sheetType == 'wide' ? 9 : 8">
				<hook zone="images">
					<v-row>
						<v-col cols="12" md="6" :order-md="sheetType == 'wide' ? 1 : 2">
							<ProductImagesCarousel
								v-if="sheetType == 'wide'"
								v-model="cImgIndex"
								:scale-down="false"
								:product="product"
								vertical
							/>
							<ProductImagesVertical
								v-else
								v-model="cImgIndex"
								:scale-down="false"
								:product="product"
							/>
						</v-col>
						<v-col cols="12" md="6" :order-md="sheetType == 'wide' ? 2 : 1">
							<div
								style="height: fit-content"
								class="px-0 px-md-6"
								:class="!rightColInView && 'sticky-right-col'"
								ref="rightColContent"
							>
								<v-row>
									<v-col cols="10">
										<h1 class="header font-weight-bold font-6 py-1">{{ product.name }}</h1>
										<!-- USER REVIEWS -->

										<hook
											zone="user-reviews"
											v-if="
												reviewsConfig &&
												reviewsConfig.enabled &&
												(reviewsConfig.permissions == 'free' ? true : product.ratingCount > 0)
											"
										>
											<div
												class="mb-2 user-reviews-btn d-flex align-center"
												@click="scrollToReviews"
											>
												<v-rating
													dense
													half-increments
													v-model="cRatingAvg"
													:color="$vars.strongtext"
													background-color="#cececd"
													small
													readonly
												></v-rating>
												<p class="mb-0 ml-1 d-inline-block link--text">
													({{ product.ratingCount }})
												</p>
											</div>
										</hook>
										<!-- END USER VALORATIONS -->

										<hook zone="brand-sku">
											<div class="d-flex align-center">
												<div v-if="product.brand" class="mr-2">
													<SafeLink :to="$shop.getShopRoute({ brand: product.brand.urlName })">
														Marca:
														<span class="font-weight-bold">{{ product.brand.name }}</span>
													</SafeLink>
												</div>
												<div>
													Sku: <span class="font-weight-bold">{{ selectedVariant.sku }}</span>
												</div>
											</div>
											<div class="my-2" v-if="marketplaceMode && showSeller">
												<div v-if="product.seller.logo" style="height: 20px" class="mt-2 mb-1">
													<Media
														:src="product.seller.logo"
														height="20px"
														max-width="130px"
														position="left"
													/>
												</div>
												<div>
													Producto vendido por
													<span class="font-weight-bold">{{
														product.seller.name.toUpperCase()
													}}</span>
												</div>
											</div>
										</hook>
										<CollectionEventIcon
											multiple
											:product="product"
											class="event-icon"
											:limitAmount="$b.m ? 5 : null"
										/>
										<ProductPage-Description
											:templateData="product.info"
											:product="product"
											class="pa-3"
											no-title
										/>
									</v-col>
									<v-col cols="2" class="text-right">
										<div
											class="wishlist-share w100 h100 d-flex flex-column align-center"
											:class="{ 'align-end': $b.t }"
										>
											<ProductWishlistToggler
												class="flex-grow mb-3"
												:productId="product.id"
												:heartWidth="25"
											/>
											<ShareProduct :productName="product.name" />
										</div>
									</v-col>
								</v-row>
							</div>
						</v-col>
					</v-row>
				</hook>
			</v-col>
			<v-col cols="12" :md="sheetType === 'wide' ? 3 : 4" class="d-flex">
				<v-row :class="!rightColInView && 'sticky-right-col'">
					<v-col cols="12" v-if="hasLimitedTimeOffer" class="mt-md-2">
						<ProductPage-LimitedTimeOffer :product="product" :productOffers="productOffers" />
					</v-col>
					<v-col cols="12">
						<div style="position: relative">
							<hook zone="price-layout">
								<PriceLayout
									:product="product"
									:prev-price="selectedVariant.pvPrice.prevPrice"
									:price="selectedVariant.pvPrice.price"
									:discount-pct="selectedVariant.pvPrice.discountPct"
									:unit-metric="product.packMetric ? product.unitMetric : null"
									:currency="selectedVariant.pvPrice.priceConfig.currency.sign"
									price-class="font-8"
								/>
							</hook>
							<div class="d-inline-block promotions link--text my-4" @click="showPromotions = true">
								Mirá todas las promociones
							</div>
							<ProductPage-PaymentsModal
								v-model="showPromotions"
								:selectedVariantId="selectedVariant.id"
							/>
							<div
								class="my-2 py-1 px-2 primary rounded"
								style="width: fit-content"
								v-if="financingTagHtml"
								v-html="financingTagHtml"
							></div>
							<div
								class="my-2 py-1 px-2 secondary rounded"
								style="width: fit-content"
								v-for="(item, i) of discountsTagsTexts"
								:key="i"
							>
								{{ item.msg }} <span class="discount-tag-price">${{ item.amount }}</span>
							</div>

							<div class="py-2 font-1">
								<ProductStockAvailabilityMessage
									v-if="!hideStockAvailabilityMessage"
									:stock="selectedVariant.stock"
									:product="product"
									class="py-1"
								/>
								<VariantsSelector
									v-model="selectedVariantId"
									:product="product"
									class="pb-3"
									:show-select-input="true"
								/>
								<StockReminderForm :product="product" :variant="selectedVariant" class="pa-2" />
								<div class="d-flex align-center py-1" v-if="selectedVariant.type == 'digital'">
									<v-icon class="mr-2">mdi-cloud-download</v-icon>
									{{ 'Producto digital descargable' | lang }}
								</div>
							</div>
							<hook zone="actions">
								<ProductActions
									:product="product"
									:variant="selectedVariant"
									:customization="customization"
									:ctaText="buttonText"
									@validation="setValidation($event)"
									class="py-3"
								/>
							</hook>
							<div class="my-1 pt-2 mx-n3 px-3 mx-md-n9 px-md-9">
								<ProductPage-DeliveryCalc
									:currency="selectedVariant.pvPrice.priceConfig.currency.sign"
									:selectedVariantId="selectedVariantId"
								/>
							</div>
							<div>
								<ProductPage-AdditionalPaymentInfo :product="product" />
							</div>
							<div
								class="pa-4 grey lighten-4 rounded-lg"
								v-if="$srv('orderTotalGoalProgressConfig.showInProductPage')"
							>
								<OrderTotalGoalProgress use-image />
							</div>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<template>
	<v-dialog v-model="showPromotions" max-width="750px">
		<v-card :style="'background-color: ' + $vars.base">
			<v-card-title class="d-flex align-center">
				<div>Medios de pago</div>
				<v-spacer />
				<Button icon depressed @click="showPromotions = false"><v-icon>mdi-close</v-icon></Button>
			</v-card-title>
			<div class="px-6 pb-5">
				<div class="d-flex justify-center py-2" v-if="loading">
					<v-progress-circular :width="3" color="red" indeterminate></v-progress-circular>
				</div>
				<template v-else-if="!paymentSelectors || paymentSelectors.length === 0">
					No hay medios de pago para este producto
				</template>
				<div class="py-4" v-else>
					<div v-for="(payment, n) in paymentSelectors" :key="n">
						<div class="d-flex">
							<Media :src="payment.image" class="mr-2" />
							<div>
								<div class="font-1 font-weight-bold">{{ payment.title }}</div>
								<div v-for="(msg, i) of payment.messages" :key="i" class="py-1 d-flex align-start">
									<v-icon class="mr-1 message-icon">mdi-chevron-right</v-icon>
									<div class="font-1">{{ msg.text }}</div>
								</div>
							</div>
						</div>
						<div v-if="methodCompName(payment)">
							<component :is="methodCompName(payment)" :data="payment.checkoutData" />
						</div>
						<v-divider class="my-8" v-if="n < paymentSelectors.length - 1" />
					</div>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { isComponent } from '@/v-shop/autoloader'

export default {
	props: {
		value: Boolean,
		selectedVariantId: String,
		qty: String,
	},
	data() {
		return {
			paymentSelectors: null,
			loading: true,
			error: false,
		}
	},
	filters: {
		formatPayment(value) {
			if (!value) return
			return value > 1 ? `${value} cuotas` : `${value} cuota`
		},
	},
	methods: {
		clear() {
			this.paymentSelectors = null
			this.error = false
		},
		methodCompName(selection) {
			let compName = `ProductPage-PaymentsModal-${selection.method.methodKey}`
			return isComponent(compName) ? compName : null
		},
		fetch() {
			const data = {
				items: [{ refId: this.selectedVariantId, refType: 'product', qty: this.qty || 1 }],
			}

			this.$shopApi.post({
				url: '/simulation/payment',
				data,
				loading: (v) => (this.loading = v),
				onSuccess: ({ data }) => {
					this.paymentSelectors = data.paymentSelectors
				},
				onError: () => {
					this.error = true
					// console.log(error)
				},
			})
		},
	},
	watch: {
		value(val) {
			if (val) {
				this.fetch()
			} else {
				this.clear()
			}
		},
	},
	computed: {
		showPromotions: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
	cssVars: {
		selector: '.product-page',
	},
}
</script>

<script>
import Base from '#/v-shop-base-layout/vue-src/v-shop/product/comp_ProductImagesCarousel.vue'

export default {
	extends: Base,
	data() {
		return {
			maxProductItems: 4,
			showRestMedia: false,
		}
	},
	methods: {
		doShowDialog(index) {
			this.currentIndex = index
			this.showDialog = true
		},
	},
	computed: {
		displayedMediaItems() {
			return this.showRestMedia ? this.mediaItems : this.mediaItemsBatch
		},
	},
}
</script>

<template>
	<div class="grid">
		<div
			v-for="(mediaItem, n) in displayedMediaItems"
			:key="n"
			style="position: relative"
			class="pointer"
			@click="doShowDialog(n)"
		>
			<Media
				:src="isVideo(mediaItem) ? mediaItem : mediaItem.square"
				:controls="false"
				:scale-down="scaleDown"
				aspect-ratio="1"
				width="100%"
				loader
			/>
			<div v-if="isVideo(mediaItem)" class="d-flex align-center justify-center video-overlay">
				<v-icon large v-if="mediaItem.type === 'youtube-video'">mdi-youtube</v-icon>
				<v-icon large v-else color="white"> mdi-play-box</v-icon>
			</div>
		</div>
		<div class="show-more-btn" v-if="mediaItems.length > maxProductItems">
			<Button @click="showRestMedia = !showRestMedia" :color="$vars.link">
				<span class="link">Mostrar {{ showRestMedia ? 'Menos' : 'Más' }}</span>
			</Button>
		</div>
		<ProductImagesDialog v-model="currentIndex" :media-items="mediaItems" :show-dialog.sync="showDialog" />
	</div>
</template>

<style scoped lang="scss">
.grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	position: relative;
}

.video-overlay {
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
}

.show-more-btn {
	display: flex;
	justify-content: center;
	position: absolute;
	inset: auto 0 -22px 0;
}
</style>


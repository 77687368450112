<script>
export default {
	props: {
		product: Object,
	},
	data() {
		return {
			collectionId: this.$srv('ProductAdditionalInfo.collectionId'),
			info: this.$srv('ProductAdditionalInfo.info'),
		}
	},
	computed: {
		showItems() {
			return this.info?.length > 0 && this.product.collectionsIds.some((id) => id == this.collectionId)
		},
	},
}
</script>

<template>
	<div class="py-2 my-1 mx-n3 px-3 mx-md-n9 px-md-9" style="border-top: 1px solid #eee" v-if="showItems">
		<IconTextItem v-for="(item, i) of info" :key="i" :item="item" />
	</div>
</template>

<script>
export default {
	props: {
		name: String,
		attributes: Array,
	},
	computed: {
		groupedAttributes() {
			return this.attributes.reduce((accum, { attrKey: { k }, v }) => {
				accum[k] ? accum[k].push(v) : (accum[k] = [v])
				return accum
			}, {})
		},
	},
}
</script>

<template>
	<div v-if="attributes.length">
		<h2 class="is-heading header font-3 mb-5">Características principales</h2>
		<v-simple-table class="rounded font-2">
			<template v-slot:default>
				<tbody class="attributes-table">
					<tr v-for="(value, key, index) in groupedAttributes" :key="index">
						<th>
							<h3 class="heading">{{ key }}</h3>
						</th>
						<td>
							<p class="pb-0">{{ value.join(', ') }}</p>
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<style lang="scss">
.v-data-table__wrapper {
	border-radius: 10px;
}
</style>

<style lang="scss" scoped>
.attributes-table {
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;

	th {
		width: 35%;
	}

	th,
	td {
		font-size: 15px !important;
		border-bottom: 0 !important;
		p {
			margin-bottom: 0;
		}
	}

	tr:nth-child(odd) {
		th {
			background-color: #ebebeb;
		}
		td {
			background-color: #f5f5f5;
		}
	}

	tr:nth-child(even) {
		th {
			background-color: #f5f5f5;
		}
		td {
			background-color: #ffffff;
		}
	}

	&__header {
		padding: 15px 24px 15px 16px;
	}
}
</style>

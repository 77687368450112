<script>
// import shouldLoginMixin from '@/v-shop/mixins/should-login-mixin'
import { get } from 'vuex-pathify'

export default {
	props: {
		product: Object,
		variant: Object,
	},
	data() {
		return {
			config: this.$srv('stockNotificationFormConfig'),
			name: '',
			email: '',
			loading: false,
			state: 'pending',
			validation: {},
			btnStatus: 'default',
		}
	},
	computed: {
		user: get('shop/user'),
	},
	methods: {
		async submit() {
			this.btnStatus = 'loading'
			await this.$shopApi.post({
				url: '/catalog/stock-notifications',
				onValidation: ({ validation }) => (this.validation = validation),
				data: {
					productVariantId: this.variant.id,
					email: this.email,
					name: this.name,
				},
				onError: () => {
					this.btnStatus = 'error'
				},
				onSuccess: () => (this.btnStatus = 'success'),
			})
		},
		setDefaults() {
			this.email = this.user?.accountEmail || ''
			this.name = `${this.user?.firstname || ''} ${this.user?.lastname || ''}`.trim()
		},
	},
	watch: {
		user() {
			this.setDefaults()
		},
	},
	created() {
		this.setDefaults()
	},
}
</script>

<template>
	<Validator
		:validation="validation"
		v-if="config && product.shopable && variant.stock.availability == 'OutOfStock'"
	>
		<form @submit.prevent="submit">
			<div class="text-center pa-2 rounded-t secondary">{{ config.titleText }}</div>
			<div class="px-2 rounded-b pb-2 bordered">
				<TextField
					v-model="name"
					label="Nombre"
					class="my-2"
					outlined
					dense
					:disabled="btnStatus === 'success'"
				/>
				<TextField
					v-model="email"
					label="Email"
					class="my-2"
					outlined
					dense
					:disabled="btnStatus === 'success'"
				/>
				<IndeterminateProgressBtn
					v-model="btnStatus"
					default-color="cta"
					success-color="success"
					error-color="error"
					@click="submit()"
					:error-duration="1000"
					block
					center-content
					allow-scroll
					disable-overlay
				>
					<v-icon left> mdi-bell </v-icon>
					{{ config.buttonText }}
					<template #loading> Enviando... </template>
					<template #success>
						<v-icon left> mdi-check </v-icon>
						{{ config.successText }}
					</template>
					<template #error>
						<v-icon left> mdi-close-thick </v-icon>
						Lo sentimos, ha ocurrido un error
					</template>
				</IndeterminateProgressBtn>
			</div>
		</form>
	</Validator>
</template>

<style scoped lang="scss">
.bordered {
	border: 1px solid var(--secondary);
}
</style>

export default {
	computed: {
		productSheetDesignConfig() {
			let config = this.$srv('productSheetDesign')
			if (!config) return

			let found
			if (config.collections?.length) {
				found = config.collections.find((item) => {
					return this.product.collectionsIds.some((id) => id == item.collectionId)
				})
			}
			if (found) return found
			else return config
		},
	},
}

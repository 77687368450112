<script>
import sortBy from 'lodash/sortBy'

export default {
	props: {
		options: Object,
		currency: String,
		zipcode: [String, Number],
	},
	data() {
		return {
			dialog: false,
			dialogMethodKey: null,
		}
	},
	methods: {
		openDialog(methodKey) {
			this.dialogMethodKey = methodKey
			this.dialog = true
		},
	},
	computed: {
		pickupPointOptions() {
			return sortBy(this.options.PickupPoint || [], [
				'total',
				(x) => (x.toAddress?.zipcode?.code == this.zipcode ? -1 : 1),
				'optionName',
			])
		},
		shippingOptions() {
			return this.options.Shipping || []
		},
		defaultOptions() {
			// Return the option with the lowest cost
			let shippingDefaultOption = this.shippingOptions.reduce((prev, curr) => {
				if (!prev) return curr
				return prev.total <= curr.total ? prev : curr
			}, null)
			let pickupPointDefaultOption = this.pickupPointOptions.reduce((prev, curr) => {
				if (!prev) return curr
				return prev.total <= curr.total ? prev : curr
			}, null)

			return [shippingDefaultOption, pickupPointDefaultOption].filter(Boolean)
		},
		hasNoOptions() {
			return !this.pickupPointOptions.length && !this.shippingOptions.length
		},
		dialogOptions() {
			if (!this.dialogMethodKey) return null
			return this.options[this.dialogMethodKey] || []
		},
		dialogTitle() {
			return this.dialogOptions?.[0]?.methodName
		},
	},
}
</script>

<template>
	<div class="product-page__shipment-calculator-results">
		<div v-if="hasNoOptions" class="grey lighten-5 bleft rounded-sm pa-2 font-0">
			No encontramos opciones de entrega para tu código postal.
		</div>
		<div v-else>
			<!-- Default options -->
			<div
				class="d-flex align-center justify-space-between grey lighten-5 bleft rounded-sm pa-2 px-sm-3 my-2 font-1"
				v-for="option of defaultOptions"
				:key="option.hash"
			>
				<div>
					<span>{{ option.methodName }}</span>
					<div class="d-flex align-center pt-1" v-if="option.hasCost">
						<PriceText
							v-if="option.discount"
							:amount="option.cost"
							:currency="currency"
							style="text-decoration: line-through"
							class="mr-2"
						/>
						<PriceText
							v-if="option.total !== 0"
							:amount="option.total"
							:currency="currency"
							class="font-weight-bold"
						/>
						<div v-else class="font-weight-bold success--text">Gratis</div>
					</div>
				</div>
				<Button color="link" text small @click="openDialog(option.methodKey)"> VER OPCIONES </Button>
			</div>

			<!-- Options Dialog -->
			<v-dialog v-model="dialog" width="600">
				<v-card>
					<v-card-title> {{ dialogTitle }} </v-card-title>
					<v-card-text>
						<div
							v-for="option of dialogOptions"
							:key="option.hash"
							class="my-2 pa-2 grey lighten-5 rounded-sm bleft"
						>
							<component
								:is="`DeliveryOptionContent-${option.methodKey}`"
								v-bind="{
									option,
									currency,
									showCost: true,
									showEta: true,
									showBeforePaymentMessages: true,
								}"
							/>
						</div>
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="base" text @click="dialog = false"> cerrar </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<style scoped>
.product-page__shipment-calculator-results .bleft,
.v-dialog .bleft {
	border-left: 4px solid var(--link) !important;
}
</style>


<template>
	<div>
		<v-icon v-if="item.iconType === 'default'" :color="$vars.basetext">{{ item.icon }}</v-icon>
		<Media v-if="item.iconType === 'image'" :src="item.iconImage" width="24px" height="24px" />
		<div v-if="item.iconType === 'html'" v-html="item.iconHtml" class="mr-2" />
	</div>
</template>

<script>
export default {
	props: {
		item: Object,
	},
}
</script>

<style></style>

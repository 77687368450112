<script>
export default {
	props: {
		showDialog: Boolean,
		indexValue: Number,
		mediaItems: Array,
	},
	model: {
		prop: 'indexValue',
		event: 'updateIndexValue',
	},
	data() {
		return {
			currentIndex: this.indexValue,
			mountCarousel: false,
			scale: 0,
		}
	},
	watch: {
		indexValue(value) {
			this.currentIndex = value || 0
		},
		currentIndex(value, oldValue) {
			this.$emit('updateIndexValue', value)
			this.scale = 0
			this.stopVideoPlayback(oldValue)
		},
		showDialog(value) {
			this.scale = 0
			setTimeout(() => (this.mountCarousel = value), 50)
		},
	},
	methods: {
		stopVideoPlayback(index) {
			const mediaContainer = this.$refs.mediaContainer?.$el
			if (!mediaContainer) return

			const oldSlide = mediaContainer.querySelector(`.img:nth-child(${index + 1}) video`)
			if (oldSlide) {
				// oldSlide.currentTime = 0
				oldSlide.pause()
			}
		},
		closeDialog() {
			this.$emit('update:show-dialog', false)
		},
		toggleScale() {
			this.scale += 1
			if (this.scale > 2) {
				this.scale = 0
			}
		},
	},
}
</script>

<template>
	<v-dialog :value="showDialog" persistent dark fullscreen transition="fade-transition">
		<div class="d-flex align-center justify-center overlay">
			<Button class="place-up" absolute top right icon x-large @click="closeDialog" dark>
				<v-icon x-large>mdi-close</v-icon>
			</Button>
			<Carousel
				ref="mediaContainer"
				v-if="mountCarousel"
				v-model="currentIndex"
				class="w100 h100"
				v-bind="
					$b.props({
						showArrows: { d: true },
						showDots: true,
						noDrag: { d: true },
						gutter: 0,
					})
				"
			>
				<div
					v-for="(mediaItem, i) of mediaItems"
					:key="i"
					class="img w100 h100"
					:class="{
						scale1: scale == 1,
						scale2: scale == 2,
						active: currentIndex == i,
						'py-8': $b.d,
						'pt-60': $b.m,
						'pt-80': $b.t,
					}"
				>
					<Media
						:src="!mediaItem._multiple ? mediaItem : mediaItem.big"
						height="100%"
						width="100%"
						scale-down
						loader
						@click="toggleScale()"
						:video-props="{
							class: 'text-center',
							videoAttrs: {
								controls: true,
								width: $b.d ? 'auto' : '100%',
								height: $b.d ? '100%' : 'auto',
							},
						}"
						:youtube-video-props="{
							iframeAttrs: {
								frameborder: 0,
							},
						}"
					/>
				</div>
			</Carousel>
		</div>
	</v-dialog>
</template>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.place-up {
	z-index: 999;
}
.overlay {
	background-color: rgba(0, 0, 0, 0.8);
	height: 100%;
}
.img {
	transform-origin: center;
	transition: 0.3s all;
}
.img.active.scale1 {
	transform: scale(1.2);
}
.img.active.scale2 {
	transform: scale(1.4);
}

.pt-80 {
	padding-top: 80px !important;
}

.pt-60 {
	padding-top: 60px !important;
}

::v-deep div.ssr-carousel,
::v-deep div.ssr-carousel-slides,
::v-deep div.ssr-carousel-mask,
::v-deep div.ssr-carousel-track {
	height: 90vh;
}

::v-deep .ratio-box__slot {
	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

::v-deep button.ssr-carousel {
	@media (min-width: 900px) {
		&-next-button {
			right: 2%;
		}
		&-back-button {
			left: 2%;
		}
	}
	// &-next-button {
	// 	@media (min-width: 900px) {
	// 		right: 2%;
	// 	}
	// }
	// &-back-button {
	// 	@media (min-width: 900px) {
	// 		left: 2%;
	// 	}
	// }
}

// @media (min-width: 900px) {
// 	::v-deep button.ssr-carousel-next-button {
// 		right: 2%;
// 	}
// 	::v-deep button.ssr-carousel-back-button {
// 		left: 2%;
// 	}
// }
</style>


<script>
import productMixin from '@/v-shop/mixins/product-mixin'

export default {
	mixins: [productMixin('page')],
	props: {
		product: {
			type: Object,
			required: true,
		},
		reviews: Array,
		reviewsCount: Array,
		totalReviews: Number,
		loadingReviewsCount: Boolean,
		loadingReviews: Boolean,
		skeletonLoaderQty: {
			type: [Number, String],
			default: 5,
		},
	},
	data() {
		return {
			reviewsDialog: false,
			reviewsConfig: this.$srv('reviewsConfig'),
		}
	},
	methods: {
		calculatePercent(quantity) {
			return (quantity * 100) / this.totalReviews
		},
	},
}
</script>

<template>
	<div :class="{ 'px-14 py-6': $b.td, 'pa-6': $b.m }">
		<div class="d-flex mb-7 flex-column flex-md-row justify-md-space-between align-md-center align-start">
			<h3 class="font-4 header font-weight-bold">
				<span class="font-weight-light">Opiniones sobre: </span>{{ product.name }}
			</h3>
			<div v-if="product.ratingCount > 0">
				<Button text @click="$emit('create-review')" class="link--text mt-2 mb-md-0">
					<v-icon>mdi-star-plus</v-icon> Deja tu opinión
				</Button>
			</div>
		</div>

		<div class="d-flex align-center" :class="{ 'flex-column pb-6': $b.m }">
			<div>
				<Media :src="mainImage" />
			</div>

			<div :class="{ 'middle-line ml-10': $b.td, 'd-none': $b.m }"></div>

			<v-skeleton-loader v-if="loadingReviewsCount" type="article, actions"></v-skeleton-loader>

			<div
				v-else-if="product.ratingCount == 0"
				class="px-4 font-weight-bold d-flex flex-column justify-center align-center flex-grow-1"
			>
				<span class="font-2">Se el primero en dejar tu opinión</span>
				<Button text @click="$emit('create-review')" class="link--text mt-2 mb-md-0">
					<v-icon>mdi-star-plus</v-icon> Deja tu opinión
				</Button>
			</div>

			<div class="d-flex flex-column mx-10" :class="{ 'mt-4': $b.m }" v-else>
				<div class="d-flex align-center">
					<div class="font-16 font-weight-bold">
						{{ product.ratingAvg }}
					</div>
					<div class="d-flex flex-column">
						<v-rating
							:value="product.ratingAvg"
							:color="$vars.strongtext"
							background-color="#cececd"
							half-increments
							readonly
						></v-rating>
						<div class="px-3">
							Opiniones: <span class="font-weight-bold">{{ product.ratingCount }}</span>
						</div>
					</div>
				</div>

				<div v-for="(item, index) of reviewsCount" :key="index">
					<div class="d-flex align-center justify-space-between">
						<v-progress-linear
							color="#cececd"
							rounded
							:value="calculatePercent(item.quantity)"
							style="width: 80%"
							height="10px"
						></v-progress-linear>
						<div>{{ item.rating }} <v-icon color="#cececd" size="15px">mdi-star</v-icon></div>
					</div>
				</div>
			</div>
		</div>

		<!-- <ProductValorations-ValorationCard v-for="(review, index) of reviews" :key="index" :review="review" /> -->

		<template v-if="!loadingReviews">
			<div
				class="review-item"
				style="border-bottom: 1px solid #cececd94"
				v-for="(review, index) of reviews"
				:key="index"
			>
				<!-- Desktop -->
				<div class="d-none d-md-flex align-center">
					<v-icon class="review-item__icon mr-8" size="80" color="grey lighten-2">
						mdi-account-circle
					</v-icon>
					<div style="max-width: 300px" class="d-none d-md-block">
						<div>
							<span class="font-weight-bold">{{ review.user.firstname }}</span>
						</div>
						<div class="review-item__text line-clamp-2">
							{{ review.text }}
						</div>
					</div>
					<v-spacer></v-spacer>
					<v-rating
						dense
						:value="review.rating"
						:color="$vars.strongtext"
						background-color="#cececd"
						:size="$b.m ? 20 : 30"
						half-increments
						readonly
					></v-rating>
				</div>
				<!-- Mobile -->
				<div class="d-flex align-center d-md-none py-3">
					<v-icon class="review-item__icon mr-8" size="80" color="grey lighten-2">
						mdi-account-circle
					</v-icon>
					<div style="max-width: 300px">
						<div class="mb-1">
							<span class="font-weight-bold">{{ review.user.firstname }}</span>
						</div>
						<div class="mb-1">
							<v-rating
								dense
								:value="review.rating"
								:color="$vars.strongtext"
								background-color="#cececd"
								:size="$b.m ? 20 : 30"
								half-increments
								readonly
							></v-rating>
						</div>
						<div class="review-item__text line-clamp-3">
							{{ review.text }}
						</div>
					</div>
				</div>
			</div>
			<slot name="below-reviews"> </slot>
		</template>
		<v-skeleton-loader v-else :type="`list-item-avatar-two-line@${skeletonLoaderQty}`"></v-skeleton-loader>
	</div>
</template>

<style>
.middle-line {
	height: 16em;
	background: #cececd94;
	width: 1px;
}

.bottom-line::after {
	content: '';
	border-bottom: 1px solid #cececd94;
	display: block;
	width: 95%;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}
.error-vuetify {
	color: #9f0010;
	background-color: #ffe4d5;
}
.success-vuetify {
	color: #006000;
	background-color: #dcffd6;
}
.item-key {
	padding: 2px 6px;
	font-weight: bold;
	background-color: #f3f3f3;
	border-radius: 4px;
	display: inline-block;
}
.item-value {
	padding: 6px 6px;
}
</style>


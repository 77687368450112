<script>
export default {
	props: {
		group: Object,
		item: Object,
		reverse: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		hasAdditionalInfo() {
			return this.group.displayAs === 'color' || !!this.item.displayMedia
		},
	},
}
</script>

<template>
	<div class="d-flex align-center pl-1 py-2" :class="{ reverse }">
		<p class="my-0" :class="{ 'mr-3': !reverse }">{{ item.v }}</p>
		<div v-if="hasAdditionalInfo" :class="{ 'mr-3': reverse }">
			<div
				v-if="group.displayAs === 'color'"
				class="color-circle"
				:style="{ backgroundColor: item.displayValue }"
			></div>
			<Media :src="item.displayMedia" width="24" v-if="group.displayAs === 'image'" />
		</div>
	</div>
</template>

<style scoped>
.color-circle {
	height: 24px;
	width: 24px;
	border-radius: 100%;
	border: 1px dotted #999;
}

::v-deep .ratio-box__slot img {
	border-radius: 100%;
}

.reverse {
	flex-direction: row-reverse;
}
</style>

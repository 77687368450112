<template>
	<v-dialog v-model="doShow" width="500">
		<v-card>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="$emit('update', false)"> Cerrar </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		value: Boolean,
	},
	computed: {
		doShow: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('update', val)
			},
		},
	},
}
</script>

<style></style>

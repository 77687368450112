<script>
import { get } from 'vuex-pathify'

export default {
	props: {
		model: Boolean,
		unhoverOnRouteLoading: Boolean,
	},
	data() {
		return { hover: false }
	},
	model: {
		prop: 'model',
		event: 'updateModel',
	},
	computed: {
		routeLoading: get('shop/routeLoading'),
	},
	watch: {
		routeLoading() {
			if (this.unhoverOnRouteLoading) this.hover = false
		},
		hover(val) {
			this.$emit('updateModel', val)
		},
	},
	methods: {
		toggle() {
			this.hover = !this.hover
		},
	},
}
</script>

<template>
	<v-hover v-model="hover" v-bind="$attrs">
		<slot :hover="hover" :toggle="toggle"></slot>
	</v-hover>
</template>

<style></style>

<script>
export default {
	props: {
		product: Object,
		productOffers: Array,
	},
	computed: {
		limitedTimeOfferMsg() {
			if (!this.productOffers.length) return
			else {
				const days = this.productOffers[0].days
				const hours = this.productOffers[0].hours
				const minutes = this.productOffers[0].minutes
				const format = (val) => (val < 10 ? `0${val}` : val)

				if (hours === 0 && days === 0) {
					return `${format(minutes)}m`
				}
				if (days === 0) {
					return `${format(hours)}h ${format(minutes)} m`
				}
				return `${format(days)} d ${format(hours)} hs ${format(minutes)} m`
			}
		},
	},
}
</script>

<template>
	<div class="d-flex justify-start align-center font-weight-medium font-2 w100 px-0">
		<div class="offer rounded py-1 px-2">
			<div>Termina en {{ limitedTimeOfferMsg }}</div>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>

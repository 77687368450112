<script>
export default {
	props: {
		scaleDown: {
			type: Boolean,
			default: true,
		},
		indexValue: Number,
		product: Object,
	},
	data() {
		return {
			showDialog: false,
			dragging: false,
			currentIndex: this.indexValue || 0,
			maxProductItems: 6,
		}
	},
	model: {
		prop: 'indexValue',
		event: 'updateIndexValue',
	},
	watch: {
		currentIndex(value) {
			this.$emit('updateIndexValue', value)
		},
		indexValue(value) {
			this.currentIndex = value
		},
	},
	methods: {
		moveTo(index, duration) {
			//this.$refs.carousel.moveTo(index, duration)
		},
		onLastImageClick(i) {
			this.currentIndex = i
			this.showDialog = true
		},
		isVideo(mediaItem) {
			return mediaItem.type && mediaItem.type.includes('video')
		},
		hasThumb(mediaItem) {
			return mediaItem.data && mediaItem.data.thumb
		},
	},
	computed: {
		mediaItemsRest() {
			return this.mediaItems.length - this.maxProductItems
		},
		mediaItemsBatch() {
			return this.mediaItems.slice(0, this.maxProductItems)
		},
		mediaItems() {
			return this.product.images.concat(this.product.videos || [])
		},
	},
}
</script>

<template>
	<div :class="$bem('product-images-carousel')">
		<div class="d-flex">
			<div :class="$bem('__thumbs-cont', 'flex-shrink-1 mr-4')" v-if="$b.td">
				<div
					v-for="(mediaItem, i) of mediaItemsBatch"
					:key="i"
					class="pb-3"
					@mouseover="currentIndex = i"
				>
					<RatioBox
						aspect-ratio="1"
						v-if="isVideo(mediaItem)"
						:class="{
							[$bem('__thumb', 'rounded-lg')]: true,
							[$bem('__thumb--active')]: currentIndex == i,
						}"
					>
						<div
							v-if="mediaItems.length > maxProductItems && i === maxProductItems - 1"
							style="height: 100%; width: 100%; background-color: rgba(0, 0, 0, 0.6)"
							class="d-flex justify-center align-center rounded"
							@click="onLastImageClick(i)"
						>
							<span style="color: white" class="font-10">+{{ mediaItemsRest }}</span>
						</div>
						<div v-else class="w-100 d-flex align-center justify-center" style="height: 100%">
							<v-icon large v-if="mediaItem.type === 'youtube-video'">mdi-youtube</v-icon>
							<v-icon large v-else> mdi-play-box</v-icon>
						</div>
					</RatioBox>
					<Media
						v-else
						:src="mediaItem.thumb"
						:class="{
							[$bem('__thumb', 'rounded-lg')]: true,
							[$bem('__thumb--active')]: currentIndex == i,
						}"
						img-class="rounded-lg"
						aspect-ratio="1"
						loader
					>
						<div
							v-if="mediaItems.length > maxProductItems && i === maxProductItems - 1"
							style="height: 100%; width: 100%; background-color: rgba(0, 0, 0, 0.6)"
							class="d-flex justify-center align-center rounded"
							@click="onLastImageClick(i)"
						>
							<span style="color: white" class="font-10">+{{ mediaItemsRest }}</span>
						</div>
					</Media>
				</div>
			</div>
			<div :class="$bem('__carousel-cont')" class="flex-grow-1 pa-sm-4">
				<Carousel
					v-model="currentIndex"
					:class="$bem('__carousel')"
					v-bind="
						$b.props({
							center: true,
							showDots: { m: true },
						})
					"
					@drag:start="dragging = true"
					@press="dragging = false"
					@release="showDialog = !dragging"
				>
					<div v-for="(mediaItem, i) of mediaItemsBatch" :key="i" @click="showDialog = true">
						<Media
							:src="isVideo(mediaItem) ? mediaItem : mediaItem.medium"
							:class="$bem('__image')"
							:scale-down="scaleDown"
							width="100%"
							aspect-ratio="1"
							loader
							:video-props="{
								class: 'text-center',
								videoAttrs: {
									width: 'auto',
								},
							}"
						/>
					</div>
				</Carousel>
			</div>
		</div>
		<ProductImagesDialog v-model="currentIndex" :media-items="mediaItems" :show-dialog.sync="showDialog" />
	</div>
</template>

<style scoped lang="scss">
.ssr-carousel ::v-deep .ssr-carousel-track {
	justify-content: center;
}
.product-images-carousel {
	&__thumb {
		cursor: pointer;
		border: 1px solid #eee;
		padding: 3px;
		&--active {
			border: 2px solid #00001d;
			padding: 2px;
		}
	}
}
</style>


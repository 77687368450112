<script>
import productMixin from '@/v-shop/mixins/product-mixin'

export default {
	mixins: [productMixin('page')],
	props: {
		value: Boolean,
		reviewsCount: Array,
		product: Object,
	},
	model: {
		prop: 'value',
		event: 'input',
	},
	data() {
		return {
			dialog: false,
			pagination: {
				page: 1,
				itemsPerPage: 5,
			},
			reviews: [],
			loading: false,
			firstLoading: true,
			firstOpen: false,
		}
	},
	methods: {
		async loadReviews() {
			const { data } = await this.$shopApi.get({
				url: '/catalog/product-reviews',
				loading: (v) => {
					this.loading = v
					if (this.firstLoading && !v) this.firstLoading = false
				},
				query: {
					filters: {
						sku: this.selectedVariant.sku,
						ean: this.selectedVariant.ean,
						sellerId: this.product.sellerId,
					},
					itemsPerPage: this.pagination.itemsPerPage,
					page: this.pagination.page,
				},
			})
			this.reviews = [...this.reviews, ...data.reviews]
			this.pagination = data.pagination
		},
	},
	watch: {
		value(v) {
			if (v && !this.firstOpen) {
				this.firstOpen = true
				this.loadReviews()
			}
			this.dialog = v
		},
		dialog(v) {
			this.$emit('input', v)
		},
		'pagination.page'() {
			this.loadReviews()
		},
	},
}
</script>

<template>
	<v-dialog v-model="dialog" width="800px" max-width="100%" v-if="firstOpen" :fullscreen="$b.m">
		<v-card width="100%">
			<div class="d-flex pa-2 justify-end" style="position: sticky; top: 0">
				<Button icon color="black" @click="dialog = false" large>
					<v-icon>mdi-close</v-icon>
				</Button>
			</div>
			<ProductReviews
				:product="product"
				:reviews="reviews"
				:total-reviews="product.ratingCount"
				:loading-reviews="loading && firstLoading"
				:reviews-count="reviewsCount"
				:can-load-more="pagination.page != pagination.lastPage"
				:skeleton-loader-qty="pagination.itemsPerPage"
				@load-more="pagination.page++"
				@create-review="$emit('create-review')"
			>
				<template #below-reviews>
					<div class="my-6">
						<Button
							text
							class="link--text"
							:disabled="pagination.page == pagination.lastPage"
							@click="pagination.page++"
							:loading="loading && !firstLoading"
						>
							Cargar más
						</Button>
					</div>
				</template>
			</ProductReviews>
		</v-card>
	</v-dialog>
</template>

<style lang="scss" scoped>
::v-deep .review-item {
	&__icon {
		align-self: flex-start !important;
	}
	&__text {
		-webkit-line-clamp: none !important;
	}
}
</style>


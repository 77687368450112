<template>
	<Button color="color1" icon style="background-color: rgba(255, 255, 255, 0.5)">
		<v-icon>mdi-share-variant-outline</v-icon>
	</Button>
</template>

<script>
export default {}
</script>

<style></style>

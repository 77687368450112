<script>
import shopProductOptions from '@/v-shop/mixins/shop-product-options-mixin'

export default {
	name: 'PriceLayout',
	mixins: [shopProductOptions({ product: Object })],
	props: {
		prevPrice: Number,
		price: Number,
		discountPct: Number,
		hidePrevPrice: Boolean,
		hideDiscount: Boolean,
		priceClass: String,
		discountClass: String,
		prevPriceClass: String,
		currency: String,
		unitMetric: String,
	},
	data() {
		return {
			dialog: false,
			paymentMethods: this.$srv('PaymentMethods.methods', []),
		}
	},
	computed: {
		hasPrevPrice() {
			return this.prevPrice && this.prevPrice > this.price
		},
		hasDiscountPct() {
			return this.discountPct > 0
		},
	},
}
</script>

<template>
	<div>
		<div v-if="!hidePrevPrice && hasPrevPrice && !spoHidePrevPrice">
			<ProductPrevPrice
				:amount="prevPrice"
				:customClass="prevPriceClass"
				:currency="currency"
				:priceClass="prevPriceClass"
			/>
		</div>
		<div class="d-flex align-center">
			<ProductPrice
				v-if="!spoHidePrice"
				class="mr-2"
				:amount="price"
				:customClass="priceClass"
				:currency="currency"
			/>
			<span v-if="unitMetric" class="mr-2 font-2 grey--text">/{{ unitMetric.split('/')[0] }}</span>
			<ProductDiscount
				v-if="discountPct && !spoHideDiscount"
				:amount="discountPct"
				:customClass="discountClass"
			/>
		</div>

		<v-dialog v-model="dialog" width="500">
			<v-card>
				<!-- <div class="close-button d-flex justify-end pa-3 align-center"> -->
				<Button
					style="right: 6px; top: 0; position: absolute"
					:ripple="false"
					icon
					@click="dialog = false"
					fab
					small
					color="base"
				>
					<v-icon color="text--base">mdi-close</v-icon>
				</Button>
				<v-card-title>Medios de pago para este producto </v-card-title>

				<v-card-text class="text-center">
					<v-row class="flex-wrap justify-space-between">
						<v-col cols="auto" class="text-start" v-for="(m, n) in paymentMethods" :key="n">
							<Media as="img" :src="m.image" height="30px" />
							<div class="link--text font-1">{{ m.label }} Cuotas</div>
						</v-col>
					</v-row>
				</v-card-text>

				<!-- <v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn :color="$vars.base" class="text--base" text @click="dialog = false"> Cerrar </v-btn>
				</v-card-actions> -->
			</v-card>
		</v-dialog>

		<!-- <PaymentOptions v-model="dialog" /> -->

		<!-- <span class="link--text pointer" @click="dialog = true">Ver todos los medios de pago</span> -->
	</div>
</template>

<template>
	<div class="d-flex my-1 align-center font-1">
		<v-icon color="base" class="mr-3 font-1">{{ item.icon }}</v-icon>
		<div>
			{{ item.text }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: Object,
	},
}
</script>

<style></style>

<script>
import Base from '#/v-shop-base-layout/vue-src/v-shop/product/comp_ProductImagesCarousel.vue'

export default {
	extends: Base,
	methods: {
		doShowDialog(index) {
			this.currentIndex = index
			this.showDialog = true
		},
	},
}
</script>

<template>
	<div>
		<div
			v-for="(mediaItem, n) in mediaItems"
			:key="n"
			style="position: relative"
			class="pointer my-2"
			@click="doShowDialog(n)"
		>
			<Media
				:src="isVideo(mediaItem) ? mediaItem : mediaItem.medium"
				:controls="false"
				:scale-down="scaleDown"
				aspect-ratio="1"
				width="100%"
				loader
			/>
			<div v-if="isVideo(mediaItem)" class="w-100 d-flex align-center justify-center" style="height: 100%">
				<v-icon large v-if="mediaItem.type === 'youtube-video'">mdi-youtube</v-icon>
				<v-icon large v-else color="white"> mdi-play-box</v-icon>
			</div>
		</div>
		<ProductImagesDialog v-model="currentIndex" :media-items="mediaItems" :show-dialog.sync="showDialog" />
	</div>
</template>

<style scoped lang="scss"></style>


<script>
export default {
	props: {
		text: {
			type: String,
			default: 'OFF',
		},
		amount: Number,
	},
}
</script>

<template>
	<span>
		<v-chip color="discount" class="py-0 px-1" small text-color="discount-text" label>
			{{ amount | discountPct }}% {{ text }}
		</v-chip>
	</span>
</template>

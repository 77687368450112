<template>
	<Container class="base rounded shadow pb-0">
		<slot></slot>
	</Container>
</template>

<script>
export default {}
</script>

<style scoped>
.shadow {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>

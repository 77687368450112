<template>
	<v-container>
		<v-row>
			<v-col class="q-px-0" cols="auto px-0" v-if="brand">
				Marca: <span class="font-weight-black">{{ brand }}</span></v-col
			>
			<v-col class="q-px-0" cols="auto" v-if="sku"
				>Sku: <span class="font-weight-black">{{ sku }}</span></v-col
			>
		</v-row>
	</v-container>
</template>

<script>
export default {
	props: {
		product: Object,
		variant: Object,
	},
	computed: {
		brand() {
			return this.product.brand?.name
		},
		sku() {
			return this.variant.sku
		},
	},
}
</script>

<style></style>

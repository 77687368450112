<script>
export default {
	props: {
		data: Object,
	},
	methods: {
		shouldBeInverted(item) {
			return this.$b.smAndUp && item.inverted
		},
	},
}
</script>

<template>
	<v-container class="mt-6">
		<v-row
			:class="$bem('template')"
			class="mb-12"
			justify="center"
			v-for="(item, i) in data.descriptionItems"
			:key="i"
		>
			<v-col
				:order="shouldBeInverted(item) ? 2 : 1"
				:class="$bem('__item')"
				class="d-flex flex-column justify-center"
			>
				<h1 class="font-5 mb-6 font-weight-regular header">{{ item.title }}</h1>
				<div v-html="item.richDescription" v-if="item.useRichText"></div>
				<div class="font-2" v-else v-html="item.content"></div>
			</v-col>
			<v-col :order="item.inverted ? 1 : 2" :class="$bem('__item')">
				<Media
					:class="shouldBeInverted(item) ? $bem('__item--left') : $bem('__item--right')"
					:src="item.image"
				/>
			</v-col>
		</v-row>

		<v-row
			v-for="(item, i) in data.dropdownItems"
			:key="i + item.title"
			justify="center"
			:class="$bem('template')"
		>
			<v-col
				:order="shouldBeInverted(item) ? 2 : 1"
				:class="$bem('__item')"
				class="d-flex flex-column justify-center"
			>
				<h1 class="font-5 mb-6 font-weight-regular header">{{ item.title }}</h1>
				<v-expansion-panels flat tile accordion>
					<v-expansion-panel v-for="(item, n) in item.dropdowns" :key="n + item.title" class="mb-1 base">
						<v-expansion-panel-header class="bordered">
							<h2 class="font-1 font-weight-regular">{{ item.title }}</h2>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div v-html="item.richDescription" class="font-2" v-if="item.useRichText"></div>
							<p class="font-2" v-else v-html="item.content"></p>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
			<v-col :order="shouldBeInverted(item) ? 1 : 2" :class="$bem('__item')">
				<Media
					v-if="item.image"
					:src="item.image"
					:class="shouldBeInverted(item) ? $bem('__item--left') : $bem('__item--right')"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<style lang="scss" scoped>
::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
	min-height: 48px;
}
::v-deep .v-expansion-panel-header {
	padding: 8px 12px;
}
::v-deep .v-expansion-panel-content__wrap {
	padding: 12px 12px 8px;
}

.bordered {
	border: 1px solid #eee;
}
.template {
	$self: &;

	&__item {
		max-width: 520px;

		&--right {
			margin-left: auto;
		}

		&--left {
			margin-right: auto;
		}
	}
}
</style>

